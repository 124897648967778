<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'

import _ from 'lodash'
import WaveSurfer from 'wavesurfer.js'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const wavesurfer = ref<WaveSurfer>()
const wavesurferContainer = ref<HTMLElement>()
const isPlaying = ref(false)
const currentSpeedIndex = ref(0)
const speedOptions = [ 1, 1.5, 2 ]
const audioDuration = ref<number | undefined>(0)
const currentAudioTime = ref<number | undefined>(0)
const file = ref()

const fromContact = computed(() => props.message.fromContact)

const download = () => {
  downloadBuilder(String(file.value), `${uuidv4()}.mp3`)
}

const formatSeconds = (time: number) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const togglePlay = () => {
  if (wavesurfer.value) {
    wavesurfer.value.playPause()
  }
}

const changeSpeed = () => {
  currentSpeedIndex.value = currentSpeedIndex.value + 1

  if (currentSpeedIndex.value > speedOptions.length - 1) {
    currentSpeedIndex.value = 0
  }

  if (wavesurfer.value) {
    wavesurfer.value.setPlaybackRate(
      speedOptions[currentSpeedIndex.value],
      true
    )
  }
}

onMounted(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        })
      )
    ).data
  }

  nextTick(() => {
    if (wavesurferContainer.value !== undefined && file.value !== undefined) {
      wavesurfer.value = WaveSurfer.create({
        container: wavesurferContainer.value,
        waveColor: '#929191',
        progressColor: '#282626',
        cursorColor: '#494747',
        cursorWidth: 2,
        barWidth: 3,
        barGap: 1,
        barRadius: 2,
        barHeight: 1.6,
        minPxPerSec: 1,
        height: 30,
        url: file.value,
      })

      wavesurfer.value.on('ready', () => {
        audioDuration.value = wavesurfer.value?.getDuration()
      })

      wavesurfer.value.on('play', () => {
        isPlaying.value = true
      })

      wavesurfer.value.on('timeupdate', (time) => {
        currentAudioTime.value = time
      })

      wavesurfer.value.on('pause', () => {
        isPlaying.value = false
      })
      wavesurfer.value.on('finish', () => {
        isPlaying.value = false
      })
    }
  })
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.fromContact"
      :sender-name="message.sender_name"
      :is-media="true"
      :from-contact="message.fromContact"
    />
    <MessageActionsV2
      :message="message"
      :enable-download="true"
      :is-media="true"
      size="sm"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="(message.quoted_message as IPChat.PartialMessage)"
    />
    <div
      class="tw-bg-wc-gray/5 tw-w-full tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-relative tw-flex tw-w-full tw-items-center">
        <time
          class="tw-absolute tw-top-[100%] tw-left-[32px] tw-opacity-90 tw-text-xs tw-text-[#667781]"
        >
          <template v-if="isPlaying">
            {{ formatSeconds(Number(currentAudioTime)) }}
          </template>
          <template v-else>
            {{ formatSeconds(Number(audioDuration)) }}
          </template>
        </time>
        <q-btn
          flat
          dense
          :icon="isPlaying ? 'o_pause' : 'o_play_arrow'"
          size="14px"
          @click="togglePlay"
        />
        <div ref="wavesurferContainer" class="tw-px-1 tw-w-full" />
        <div class="tw-w-[50px] tw-flex tw-justify-center">
          <q-btn
            flat
            dense
            size="14px"
            :label="`${speedOptions[currentSpeedIndex]}x`"
            class="tw-w-full"
            style="z-index: 99999"
            @click="changeSpeed"
          />
        </div>
      </div>
    </div>
    <div class="tw-float-right">
      <time class="tw-opacity-90 tw-text-xs tw-text-[#667781]"
        >{{ $formatTime(message.time || message.created_at) }}
      </time>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="message.status as number"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
