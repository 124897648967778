<script setup lang="ts">
const props = defineProps<{
  fromContact: boolean
  isGroup: boolean
  isMedia?: boolean
}>()

const groupWrapperClasses = computed(() => ({
  wrapperGroup: props.fromContact || (!props.fromContact && !props.isMedia),
  bgColorIncoming: props.fromContact,
  bgColorOutgoing: !props.fromContact && !props.isMedia,
  wrapperDefaultForMedia: !props.fromContact && props.isMedia,
  bgColorForMedia: !props.fromContact && props.isMedia,
}))
const groupButtonClasses = computed(() => ({
  buttonWrapperForMediaIsGroup: props.isMedia,
  buttonWrapperDefault: !props.isMedia,
}))
const wrapperClasses = computed(() => ({
  wrapperDefault: !props.isMedia,
  wrapperDefaultForMedia: props.isMedia,
  bgColorIncoming: props.fromContact && !props.isMedia,
  bgColorOutgoing: !props.fromContact && !props.isMedia,
  bgColorForMedia: props.isMedia,
}))
const defaultButtonClasses = computed(() => ({
  buttonWrapperForMediaNoGroup: props.isMedia,
  buttonWrapperDefault: !props.isMedia,
}))
</script>
<template>
  <div
    :class="[
      isGroup ? groupWrapperClasses : wrapperClasses,
      'tw-z-[800]',
    ]"
  >
    <div
      class="tw-absolute"
      :class="[isGroup ? groupButtonClasses : defaultButtonClasses]"
    >
      <slot />
    </div>
  </div>
</template>
<style scoped>
.wrapperDefault {
  @apply tw-absolute tw-w-[42px] tw-h-[40px] tw-overflow-hidden tw-right-[3px] tw-top-[3px] tw-opacity-0 group-hover:tw-opacity-100 group-hover:tw-rounded-tr-[6px];
}

.wrapperDefaultForMedia {
  @apply tw-absolute tw-w-[156px] tw-h-[40px] tw-max-w-[90%] tw-overflow-hidden tw-right-[3px] tw-top-[3px] tw-opacity-0 group-hover:tw-opacity-100 group-hover:tw-rounded-tr-[6px];
}

.wrapperGroup {
  @apply tw-absolute tw-w-[42px] tw-h-[25px] tw-max-w-[90%] tw-overflow-hidden tw-right-[1px] tw-top-[1px] tw-opacity-0 group-hover:tw-opacity-100 group-hover:tw-rounded-tr-[6px];
}

.buttonWrapperForMediaNoGroup {
  @apply tw-right-[2px] tw-top-[2px];
}

.buttonWrapperForMediaIsGroup {
  @apply tw-right-[5px] tw-top-[2px];
}

.bgColorForMedia {
  background: linear-gradient(
    15deg,
    rgba(var(--overlay-bg), 0),
    rgba(var(--overlay-bg), 0) 45%,
    rgba(var(--overlay-bg), 0.12) 70%,
    rgba(var(--overlay-bg), 0.33)
  );
}

.buttonWrapperDefault {
  @apply tw-right-[1px] tw-top-1;
}

.bgColorIncoming {
  background: radial-gradient(
    at top right,
    rgba(var(--incoming-bg), 1) 60%,
    rgba(var(--incoming-bg), 0) 80%
  );
}

.bgColorOutgoing {
  background: radial-gradient(
    at top right,
    rgba(var(--outgoing-bg), 1) 60%,
    rgba(var(--outgoing-bg), 0) 80%
  );
}
</style>
