<script setup lang="ts">
const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const fromContact = computed(() => props.message.fromContact)

const openGMaps = () => {
  window.open(
    `https://www.google.com/maps/place/${props.message.message.degreesLatitude},${props.message.message.degreesLongitude}`
  )
}
</script>

<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.fromContact"
      :sender-name="message.sender_name"
      :is-media="true"
      :from-contact="message.fromContact"
    />
    <MessageActionsV2 :message="message" :hide-forward="true" size="sm" />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div
      class="tw-bg-wc-gray/5 tw-w-full tw-overflow-hidden tw-rounded-md tw-flex tw-items-center tw-justify-between"
    >
      <q-img :src="$asset('images/map.jpeg')">
        <div class="absolute-full text-subtitle2 flex flex-center">
          <q-btn
            label="Visualizar no Mapa"
            color="primary"
            icon="o_location_on"
            @click="openGMaps"
          />
        </div>
      </q-img>
    </div>
    <div class="tw-float-right">
      <time class="tw-opacity-90 tw-text-xs tw-text-disabled">
        {{ $formatTime(message.time || message.created_at) }}
      </time>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="message.status as number"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
