import { MessageType } from '@/Enums/MessageType'
import { Notify } from 'quasar'

/**
 * File or Blob to Base64
 * @param file File | Blob
 * @returns string | ArrayBuffer | null
 */
export const fileToBase64 = (
  file?: File | Blob
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    if (file === undefined) return
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

/**
 * Get image type
 * @param file File object
 * @returns object
 */
export const checkFileType = (file: File) => {
  const imageTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
  ]
  const videoTypes = [
    'video/mp4',
    'video/avi',
    'video/mov',
    'video/mpeg',
    'video/webm',
  ]

  if (imageTypes.includes(file.type)) {
    return {
      mime: file.type,
      extended: MessageType.IMAGE,
    }
  } else if (videoTypes.includes(file.type)) {
    return {
      mime: file.type,
      extended: MessageType.VIDEO,
    }
  } else {
    Notify.create({
      type: 'error',
      message: 'Tipo de arquivo não suportado',
      caption: 'Type is not supported',
    })
    throw new Error('Type is not supported')
  }
}

/**
 * Is media or document
 * @param file File object
 * @returns object
 */
export const checkIsMedia = (file: File) => {
  const imageTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
  ]
  const videoTypes = [
    'video/mp4',
    'video/avi',
    'video/mov',
    'video/mpeg',
    'video/webm',
  ]
  return imageTypes.includes(file.type) || videoTypes.includes(file.type)
}

/**
 * Convert data url blob to base64
 * @param blobUrl blob url - blob:http://localhost/44b7af43-478a-445b-8ccf-e0ff1d63fe26
 * @returns Promise
 */
export const convertBlobUrlToBase64 = async (
  blobUrl: string
): Promise<string> => {
  const blob = await fetch(blobUrl).then((r) => r.blob())
  const base64 = String(await fileToBase64(blob))
  return base64
}

/**
 * Convert base64 to File object
 * @param base64 base64 string
 * @param filename filename with extension
 * @returns File
 */
export const base64ToFile = (
  base64: string,
  filename: string,
  contentType = ''
): File => {
  const blob = b64toBlob(base64, contentType)
  return new File([ blob ], filename, {
    type: blob.type,
    lastModified: Date.now(),
  })
}

/**
 * Blob to File Object
 * @param blob Blob
 * @param filename string
 * @returns File
 */
export const blobToFile = (blob: Blob, filename: string) => {
  return new File([ blob ], filename, {
    type: blob.type,
    lastModified: Date.now(),
  })
}

/**
 * Base64 to Blob Object
 * @param b64Data base64 content
 * @param contentType content mimetype
 * @returns Blob
 */
export const b64toBlob = (dataURL: string, contentType = ''): Blob => {
  const arr = dataURL.split(',')
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  if (contentType === '') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    contentType = arr[0].match(/:(.*?);/)![1]
  }

  return new Blob([ u8arr ], { type: `${contentType}` })
}

/**
 * Compress loaded image
 * @param file File object
 * @param quality Quality of image
 * @returns Promise
 */
export const compressImage = (file: File, quality = 0.9): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      const img = new Image()

      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Define a largura e altura máximas para a imagem comprimida
        const maxWidth = 1920
        const maxHeight = 1080

        // Verifica se é necessário redimensionar a imagem
        let { width, height } = img
        if (width > maxWidth || height > maxHeight) {
          const ratio = Math.min(maxWidth / width, maxHeight / height)
          width *= ratio
          height *= ratio
        }

        // Define as dimensões do canvas
        canvas.width = width
        canvas.height = height

        // Desenha a imagem no canvas com as dimensões reduzidas
        if (!ctx) {
          reject(new Error('Context is not loaded'))
          return
        }

        ctx.drawImage(img, 0, 0, width, height)

        // Obtém a imagem comprimida como base64
        const compressedDataUrl = canvas.toDataURL('image/jpeg', quality)

        // Converte a URL de dados para Blob
        const blob = b64toBlob(compressedDataUrl)

        // Cria um novo objeto File com o Blob modificado
        const compressedFile = new File([ blob ], file.name, {
          type: blob.type,
          lastModified: Date.now(),
        })

        resolve(compressedFile)
      }

      img.src = e.target?.result as string
    }

    reader.onerror = function (e: ProgressEvent<FileReader>) {
      reject(new Error(`Error to read file ${e}`))
    }

    reader.readAsDataURL(file)
  })
}

/**
 * Generate Video Thumbnail
 * @param videoFile File object
 * @returns Promise<string>
 */
export const createVideoThumbnail = (
  videoUrl: string | File
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    video.addEventListener('loadeddata', () => {
      // Define a largura e altura do canvas com base nas dimensões do vídeo
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      // Calcula o tempo para o frame desejado (25% do vídeo)
      const seekTime = video.duration * 0.25

      // Navega para o tempo desejado e aguarda o evento seeked
      video.currentTime = seekTime
      video.addEventListener('seeked', () => {
        if (!context) throw new Error('Context is not loaded')
        // Desenha o frame atual do vídeo no canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Converte o canvas para uma imagem base64
        const thumbnailDataUrl = canvas.toDataURL('image/webp')

        // Resolve a promessa com a imagem base64 da thumbnail
        resolve(thumbnailDataUrl)
      })
    })

    video.src =
      videoUrl instanceof File ? URL.createObjectURL(videoUrl) : videoUrl
    video.crossOrigin = 'anonymous'
    video.load()
  })
}

export const convertLinkToFile = async (
  url: string,
  fileName: string,
  mimeType?: string
) => {
  try {
    const response = await fetch(url)
    const data = await response.blob() // Obtém os dados da resposta como um Blob
    const options = mimeType ? { type: mimeType } : { type: data.type }
    return new File([ data ], fileName, options)
  } catch (error) {
    Notify.create({
      type: 'error',
      message: 'Não foi possível converter o arquivo, tente novamente!',
      caption: String(error),
    })
    return null
  }
}

export const getFileSizeByUrl = async (url: string) => {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Erro HTTP! Status: ${response.status}`)
    }

    const buffer = await response.arrayBuffer()

    // Obter o tamanho do arquivo em bytes
    const fileSize = buffer.byteLength

    return fileSize
  } catch (error) {
    console.error('Erro:', error)
    return null
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0B'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [ 'B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i]
}
