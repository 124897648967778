<script setup lang="ts">
import _ from 'lodash'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const currentLength = ref(120)
const totalSentences = ref(0)

const isOnlyOneEmojiInjection: any = inject('isOnlyOneEmoji')

const fromContact = computed(() => props.message.fromContact)
const showStatus = computed(
  () => !props.message.fromContact && !_.isEmpty(props.message.message)
)
const isOnlyOneEmoji = computed(() => ({
  isOnlyOneEmoji: isOnlyOneEmojiInjection(props.message.message.text),
}))

const text = computed(() => formatMarkdown(String(props.message.message.text)))
const showLoadMore = computed(() => currentLength.value < totalSentences.value)
const fullText = computed(() => {
  return text.value.split(' ').splice(0, currentLength.value).join(' ')
})

const loadMore = () => {
  const toLoad = 300
  if (currentLength.value + toLoad > totalSentences.value) {
    currentLength.value += totalSentences.value - currentLength.value
  } else {
    currentLength.value += toLoad
  }
}

onMounted(() => {
  const splittedText = text.value.split(' ')
  totalSentences.value = splittedText.length
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble chat-bubble-support tw-group"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      :participant="message.participant_username"
      :sender-name="message.sender_name"
      :from-contact="message.fromContact"
    />
    <MessageActionsV2
      v-if="!_.isEmpty(message.message)"
      :message="message"
      size="sm"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div class="message-content">
      <div v-if="!_.isEmpty(message.message)" :class="[isOnlyOneEmoji]">
        <span v-html="`${fullText}${showLoadMore ? '...' : ''}`" />
        <button
          v-if="showLoadMore"
          class="tw-text-wc-blue-400 hover:tw-underline"
          @click="loadMore"
        >
          Leia mais
        </button>
      </div>
      <DeletedMessage v-else />
    </div>

    <div class="bubble-bottom">
      <BubbleTimePartial :time="$formatTime(message.time || message.created_at)" />
      <MessageStatus
        v-if="showStatus"
        class="tw-pl-1"
        :status="Number(message.status)"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
<style scoped>
.chat-bubble-support {
  @apply tw-min-w-[5.5rem] !tw-pl-[9px] !tw-pr-2;
}

.isOnlyOneEmoji {
  @apply tw-text-3xl tw-mb-2 tw-inline-flex;
}

.message-content {
  @apply tw-mb-[2px];
}

.bubble-bottom {
  @apply tw-relative tw-float-right tw-ml-1 tw-mr-0 tw-mb-[-5px] tw-mt-[-10px];
}
</style>
