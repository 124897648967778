<script setup lang="ts">
import { MessageType } from '@/Enums/MessageType'
import { formatMarkdown } from '@/Helpers/FormatMarkdown'
import _ from 'lodash'

const props = defineProps<{
  metadata: IPChat.PartialMessage
}>()

const $q = useQuasar()
const activeChatStore = useActiveChatStore()

const file = ref('')

const fromContact = computed(() => props.metadata.fromContact)
const quotedMessage = computed(() => props.metadata)
const message = computed(() => {
  let rawMessage = quotedMessage.value.message

  if (typeof rawMessage === 'string') {
    rawMessage = JSON.parse(rawMessage)
    return rawMessage
  }

  return rawMessage
})
const isTextMessage = computed(() => props.metadata.type === MessageType.TEXT)
const isDocumentMessage = computed(
  () => props.metadata.type === MessageType.DOCUMENT
)
const isImageMessage = computed(() => props.metadata.type === MessageType.IMAGE)
const isVideoMessage = computed(() => props.metadata.type === MessageType.VIDEO)
const isAudioMessage = computed(() => props.metadata.type === MessageType.AUDIO)
const isStickerMessage = computed(
  () => props.metadata.type === MessageType.STICKER
)
const isContactMessage = computed(
  () => props.metadata.type === MessageType.CONTACT
)
const isLocationMessage = computed(
  () => props.metadata.type === MessageType.LOCATION
)
const isGroup = computed(
  () => activeChatStore.currentContact.props.state.is_group
)
const quotedContactName = computed(() => {
  if (activeChatStore.currentContact.props.state.is_group) {
    if (fromContact.value && !_.isEmpty(quotedMessage.value?.sender_name)) {
      return quotedMessage.value.sender_name
    }
  } else {
    if (fromContact.value) {
      return activeChatStore.currentContact.props.contact.name
    }
  }

  return 'Você'
})
const quotedText = computed(() => String(message.value?.text))
const caption = computed(() => {
  if (message.value?.caption) {
    return message.value?.caption
  } else if (!message.value?.caption && !isStickerMessage.value) {
    return 'Image'
  } else {
    return 'Figurinha'
  }
})

const seeking = ref(false)

const goTo = async () => {
  if (seeking.value) {
    return
  }

  if (props.metadata.id === undefined) {
    $q.notify({
      type: 'error',
      message: 'Houve um erro durante a busca da mensagem',
    })
    return
  }

  seeking.value = true
  await activeChatStore.goToMessage({
    seekMessageId: props.metadata.id,
  })
  seeking.value = false
}

onMounted(async () => {
  if (!_.isEmpty(quotedMessage)) {
    if (
      isImageMessage.value ||
      isStickerMessage.value ||
      isVideoMessage.value
    ) {
      const path = message.value.media?.link
      if (message.value.linkfied) {
        file.value = String(path)
      } else {
        file.value = (
          await window.axios.post(route('get-share-link', { _query: { path } }))
        ).data
      }

      if (isVideoMessage.value) {
        file.value = await createVideoThumbnail(file.value)
      }
    }
  }
})
</script>
<template>
  <div class="tw-cursor-pointer" @click="goTo">
    <template v-if="isTextMessage">
      <div class="text tw-p-1">
        <span class="quoted-sender-name">
          {{ quotedContactName }}
        </span>
        <div class="quoted-text-mention" v-html="formatMarkdown(quotedText)" />
      </div>
    </template>
    <template v-else-if="isImageMessage || isStickerMessage || isVideoMessage">
      <div class="default tw-overflow-auto">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-text-wc-gray/50 tw-flex tw-items-center tw-gap-1">
            <q-icon
              :name="
                isImageMessage || isStickerMessage ? 'o_image' : 'o_videocam'
              "
              size="16px"
              class="tw-ml-[-2px]"
            />

            <div class="quoted-text-mention" v-html="formatMarkdown(caption)" />
          </div>
        </div>
        <q-img
          :src="file"
          :ratio="1 / 1"
          width="64px"
          spinner-size="16px"
          no-native-menu
        />
      </div>
    </template>
    <template v-else-if="isDocumentMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-text-wc-gray/50 tw-flex tw-items-center tw-gap-1">
            <q-icon name="o_description" size="16px" class="tw-ml-[-2px]" />
            <span>Documento</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isAudioMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-text-wc-gray/50 tw-flex tw-items-center tw-gap-1">
            <q-icon name="o_mic" size="16px" class="tw-ml-[-2px]" />
            <span>Áudio</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isContactMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-text-wc-gray/50 tw-flex tw-items-center tw-gap-1">
            <q-icon name="o_person" size="16px" class="tw-ml-[-2px]" />
            <span>Contato</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isLocationMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-text-wc-gray/50 tw-flex tw-items-center tw-gap-1">
            <q-icon name="o_location_on" size="16px" class="tw-ml-[-2px]" />
            <span>Localização</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.text {
  @apply tw-bg-slate-100/60 tw-rounded-[4px] tw-my-1 tw-text-xs tw-mb-1 tw-border tw-border-slate-50 tw-border-l-2 tw-border-l-wc-green-dot;
}
.default {
  @apply tw-flex tw-items-center tw-flex-row tw-bg-slate-100/60 tw-rounded-[4px] tw-my-1 tw-text-xs tw-mb-1 tw-border tw-border-slate-50 tw-border-l-2 tw-border-l-wc-green-dot;
}
</style>
