<script setup lang="ts">
const props = defineProps<{
  message: IPChat.PartialMessage
  isMedia?: boolean
  size?: string
  enableDownload?: boolean
  hideForward?: boolean
}>()

defineEmits(['downloadCallback'])

const useAuth = useAuthStore()
const modalsStore = useModalsStore()
const activeChatStore = useActiveChatStore()
const button = ref<Element>()

const fromContact = computed(() => props.message.fromContact)

const btnColor = computed(() => {
  if (
    activeChatStore.currentContact.isGroup &&
    props.isMedia &&
    fromContact.value
  ) {
    return '#8696a0'
  } else if (fromContact.value && props.isMedia) {
    return 'white'
  } else if (!fromContact.value && !props.isMedia) {
    return '#8696a0'
  }

  return '#8696a0'
})

const forwardMessage = () => {
  modalsStore.forwardMessageMetadata.fromMessageKey = String(props.message.key)
  modalsStore.toogleForwardMessageModal()
}

const eraseMessage = () => {
  activeChatStore.revokeMessage({
    messageKey: String(props.message.key),
    stateId: props.message.chat_state_id,
  })
}

const replyMessage = () => {
  activeChatStore.replyMessage(props.message)
}
</script>
<template>
  <MessageActionWrapper
    v-if="message.status && message.status >= MessageStatus.SENT"
    :from-contact="message.fromContact"
    :is-group="activeChatStore.currentContact.isGroup"
    :is-media="isMedia"
  >
    <q-icon
      ref="button"
      name="o_expand_more"
      class="!tw-cursor-pointer"
      :size="size ?? 'xs'"
      :color="btnColor"
    />
    <q-menu touch-position :target="button">
      <q-list style="min-width: 100px">
        <q-item
          v-if="!hideForward"
          v-close-popup
          clickable
          @click="forwardMessage"
        >
          <q-item-section>Encaminhar</q-item-section>
        </q-item>
        <q-item v-close-popup clickable @click="replyMessage">
          <q-item-section>Responder</q-item-section>
        </q-item>
        <q-item
          v-if="enableDownload"
          v-close-popup
          clickable
          @click="$emit('downloadCallback')"
        >
          <q-item-section>Baixar</q-item-section>
        </q-item>
        <q-item
          v-if="
            useAuth.allowDeleteMessage &&
            !message.fromContact &&
            !activeChatStore.currentContact.isGroup
          "
          v-close-popup
          clickable
          @click="eraseMessage()"
        >
          <q-item-section no-wrap> Apagar </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </MessageActionWrapper>
</template>
