<script setup lang="ts">
import _ from 'lodash'
import { getFileSizeByUrl, formatBytes } from '@/Helpers/Files'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const fromContact = computed(() => props.message.fromContact)

const bytes = ref(0)
const file = ref()

const truncateFileName = (fileName: string) => {
  const split = fileName.split('.')
  const name = split[0]
  const ext = split[1]

  if (name.length > 11) {
    const firstHalf = name.substr(0, 10)
    const secondHalf = name.substr(name.length - 10, name.length)
    let filename = firstHalf + '...' + secondHalf

    if (ext) {
      filename = `${filename}.${ext}`
    }

    return filename
  }

  let filename = name

  if (ext) {
    filename = `${filename}.${ext}`
  }

  return filename
}

const getFileBytes = () => {
  if (file.value) {
    getFileSizeByUrl(file.value).then((byteArray) => {
      if (byteArray) {
        bytes.value = byteArray
      } else {
        console.log('Failed to fetch file bytes.')
      }
    })
  }
}

onMounted(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        })
      )
    ).data
  }

  getFileBytes()
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      :participant="message.participant_username"
      :sender-name="message.sender_name"
      :from-contact="message.fromContact"
      :is-media="true"
    />
    <MessageActionsV2
      v-if="!_.isEmpty(message.message)"
      :message="message"
      size="sm"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div
      class="tw-bg-wc-gray/5 hover:tw-bg-wc-gray/10 tw-w-full tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-flex tw-w-full tw-items-center">
        <q-icon name="o_description" size="md" />
        <span class="tw-pl-1 tw-flex tw-flex-wrap tw-w-full">
          <span class="tw-w-full">{{
            truncateFileName(String(message.message.filename))
          }}</span>
          <span class="tw-text-xs">{{ formatBytes(bytes) }}</span>
        </span>
      </div>
      <q-btn
        flat
        dense
        size="md"
        icon="o_download"
        style="z-index: 99999"
        @click="downloadBuilder(file, message.message.filename)"
      />
    </div>
    <div v-if="message.message.caption" class="file-caption">
      <div class="tw-pt-1" v-html="formatMarkdown(message.message.caption)" />
      <div class="tw-right-2 tw-bottom-[0px] tw-inline-flex tw-float-right">
        <time class="tw-opacity-90 tw-text-xs tw-text-[#667781]">
          {{ $formatTime(message.time || message.created_at) }}
        </time>
        <MessageStatus
          v-if="!message.fromContact"
          class="tw-pl-1"
          :status="message.status as number"
        />
      </div>
    </div>
    <div v-else class="tw-float-right">
      <time class="tw-opacity-90 tw-text-xs tw-text-[#667781]"
        >{{ $formatTime(message.time || message.created_at) }}
      </time>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="message.status as number"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
