<script setup lang="ts">
import '@Styles/lightbox.css'
import VueEasyLightbox from 'vue-easy-lightbox/external-css'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue'),
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const imageElement = ref<HTMLImageElement>()
const openModal = ref(false)
const file = ref()

const download = () => {
  downloadBuilder(
    String(props.message.message.media?.link),
    props.message.message.filename || `${uuidv4()}.jpg`,
  )
}

onMounted(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        }),
      )
    ).data
  }
})

const fromContact = computed(() => props.message.fromContact)
const caption = computed(() => props.message.message.caption)
const imgRef = computed(() => {
  if (_.isEmpty(caption.value)) {
    return [file.value]
  }

  return [{ src: file.value, title: caption.value, alt: caption.value }]
})
const formatedCaption = computed(() =>
  formatMarkdown(String(props.message.message.caption)),
)
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-[3px] tw-rounded-lg tw-shadow-md tw-group tw-min-w-40 tw-max-w-64 sm:tw-min-w-[400px] sm:tw-max-w-[500px]"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="fromContact"
      :sender-name="message.sender_name"
      :from-contact="fromContact"
      :is-media="true"
    />
    <MessageActionsV2
      :message="message"
      :enable-download="true"
      :is-media="true"
      size="sm"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message as IPChat.PartialMessage"
    />
    <figure class="tw-relative">
      <div class="message-content" @click="openModal = true">
        <q-img ref="imageElement" :src="file" :ratio="16 / 9" />
      </div>
      <figcaption v-if="caption" class="file-caption">
        <div class="tw-pt-1">
          <SenderName
            v-if="!fromContact"
            :sender-name="message.sender_name"
            :is-media="true"
            :from-contact="fromContact"
          />
          <span v-html="formatedCaption" />
        </div>
        <div class="bubble-bottom-no-caption">
          <BubbleTimePartial
            :time="$formatTime(message.time || message.created_at)"
          />
          <MessageStatus
            v-if="!fromContact"
            class="tw-pl-1"
            :status="Number(message.status)"
          />
        </div>
      </figcaption>
      <div v-else class="bubble-bottom-caption shade">
        <div class="tw-relative tw-right-1 tw-float-right tw-top-1">
          <BubbleTimePartial
            :time="$formatTime(message.time || message.created_at)"
            :caption="true"
          />
          <MessageStatus
            v-if="!fromContact"
            class="tw-pl-1"
            :status="Number(message.status)"
          />
        </div>
      </div>
    </figure>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />

  <vue-easy-lightbox
    :visible="openModal"
    :imgs="imgRef"
    :rotate-disabled="true"
    :index="0"
    teleport="body"
    @hide="() => (openModal = false)"
  />
</template>
<style scoped>
.bubble-bottom-no-caption {
  @apply tw-relative tw-inline-flex tw-float-right tw-ml-1 tw-mr-0 tw-mb-[-5px] tw-mt-[-10px] tw-bottom-[0px] tw-items-center tw-pb-1;
}

.bubble-bottom-caption {
  @apply tw-absolute tw-w-full tw-bottom-[0px] tw-overflow-hidden tw-h-[28px] tw-rounded-br-md tw-rounded-bl-md;
}

.shade {
  background: linear-gradient(
    to top,
    rgba(var(--overlay-bg), 0.5),
    rgba(var(--overlay-bg), 0)
  );
}

.message-content {
  @apply tw-rounded-md tw-overflow-hidden tw-cursor-pointer;
}
</style>
