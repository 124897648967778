<script setup lang="ts">
const props = defineProps<{
  participant?: string
  senderName?: string
  isMedia?: boolean
  fromContact?: boolean
}>()

const activeChatStore = useActiveChatStore()
//const chatStore = useChatStore()
const isGroup = computed(() => activeChatStore.isGroup)

const handleContact = async () => {
  // console.log(props.participant)
  // if (props.participant) {
  //   const verify = await chatStore.verifyParticipantGroup({ participant: props.participant })
  //   if (verify && verify.exists) {
  //     chatStore.$patch({
  //       openContactProfileModal: true,
  //       contactProfileUsername: props.participant
  //     })
  //   } else {
  //     console.log('Adiciona novo contato')
  //   }
  // }
}
</script>
<template>
  <div v-if="senderName && !isGroup" class="senderName">
    {{ senderName }}
  </div>
  <div
    v-else-if="senderName && isGroup"
    class="senderName"
    :class="{
      media: isMedia && fromContact,
    }"
    @click="handleContact"
  >
    <span
      :class="{
        clickable: fromContact,
      }"
    >
      {{ senderName }}
    </span>
  </div>
</template>
<style scoped>
.senderName {
  @apply tw-w-full tw-text-wc-blue-400 tw-font-medium tw-leading-[0.8rem] tw-my-1;
}

.clickable {
  @apply tw-cursor-pointer hover:tw-underline;
}

.media {
  padding-left: 0.3rem;
  padding-right: 0.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
</style>
