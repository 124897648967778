<script setup lang="ts">
import { MessageType } from '@/Enums/MessageType'

const props = defineProps<{
  message: IPChat.PartialMessage
  ratio?: number
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const file = ref()

onMounted(async () => {
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      emit('close')
    }
  })

  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        })
      )
    ).data
  }
})
</script>
<template>
  <Teleport to="body">
    <div class="overlay">
      <div class="tw-absolute tw-top-[10px] tw-right-[10px]">
        <q-btn flat dense icon="o_close" size="lg" @click="$emit('close')" />
      </div>
      <div
        class="content"
        :class="{
          image: message.type === MessageType.IMAGE,
          video: message.type === MessageType.VIDEO,
        }"
      >
        <div class="tw-w-full tw-rounded-md tw-overflow-hidden">
          <video
            v-if="message.type === MessageType.VIDEO"
            :src="file"
            controls
            class="tw-w-full tw-aspect-video"
          />
          <q-img
            v-else-if="message.type === MessageType.IMAGE"
            :ratio="ratio ? ratio : 1"
            :src="file"
            class="tw-w-full"
          />
        </div>
        <div
          v-if="message.message.caption"
          class="tw-relative tw-mt-1 tw-w-full tw-text-center"
        >
          {{ message.message.caption }}
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.overlay {
  @apply tw-absolute tw-z-[9999] tw-w-full tw-h-full tw-top-0 tw-left-0 tw-bg-zinc-300/90 tw-backdrop-blur-sm;
  @apply tw-flex tw-justify-center tw-items-center;
}

.content {
  @apply tw-absolute;
  @apply tw-flex tw-flex-wrap tw-w-full;
}

.image {
  @apply tw-min-w-[400px] tw-max-w-[700px];
}

.video {
  @apply tw-min-w-[860px] tw-max-w-[1024px];
}
</style>
