<script setup lang="ts">
import { copyToClipboard } from 'quasar'
import vcard from 'vcard-json'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const fromContact = computed(() => props.message.fromContact)
const parsedVCard = computed(() =>
  vcard.parseVcardStringSync(props.message.message.vcard)
)

const $q = useQuasar()
const saving = ref(false)
const openModal = ref(false)
const copiedState = ref(false)
const form = reactive({
  name: '',
  number: '',
})

const toggleModal = () => {
  openModal.value = !openModal.value
}

const postSaveContact = async () => {
  saving.value = true

  try {
    await window.axios.post(
      route('v2.contact.createOne'),
      {
        name: form.name,
        number: form.number,
      },
      {
        'axios-retry': {
          retries: 0,
        },
      }
    )

    $q.notify({
      type: 'success',
      message: 'Contato salvo com sucesso',
    })

    toggleModal()
  } catch (e: any) {
    const error = e.response.data.error || 'Houve um erro, tente novamente!'
    $q.notify({
      type: 'error',
      message: error,
    })
  }

  saving.value = false
}

const copyNumber = () => {
  copyToClipboard(parsedVCard.value[0].phone[0].value).then(() => {
    copiedState.value = true
    setTimeout(() => (copiedState.value = false), 1200)
  })
}

const mountDialog = () => {
  form.name = parsedVCard.value[0].fullname
  form.number = parsedVCard.value[0].phone[0].value
}

const unMountDialog = () => {
  form.name = ''
  form.number = ''
}
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.fromContact"
      :sender-name="message.sender_name"
      :is-media="true"
      :from-contact="message.fromContact"
    />
    <MessageActionsV2 :message="message" size="sm" :hide-forward="true" />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="(message.quoted_message as IPChat.PartialMessage)"
    />
    <div
      class="tw-bg-wc-gray/5 tw-w-full tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-relative tw-flex tw-w-full tw-items-center">
        <div class="tw-bg-zinc-50 tw-rounded-full tw-p-1">
          <q-icon name="o_person" size="lg" />
        </div>
        <div class="tw-px-2 tw-w-full tw-flex tw-flex-col">
          <span>{{ message.message?.displayName }}</span>
          <span
            @click="copyNumber"
            class="tw-text-xs tw-text-disabled tw-cursor-pointer"
          >
            {{ parsedVCard[0].phone[0].value }}
            <q-tooltip anchor="bottom middle" self="center middle">
              {{ copiedState ? 'Copiado' : 'Copiar Telefone' }}
            </q-tooltip>
          </span>
        </div>
      </div>
    </div>
    <div
      class="tw-w-full tw-rounded-md tw-mt-1 tw-flex tw-items-center tw-justify-center"
    >
      <q-btn
        dense
        flat
        label="Salvar Contato"
        size="12px"
        class="full-width"
        color="primary"
        @click="toggleModal"
      />
    </div>
    <div class="tw-float-right">
      <time class="tw-opacity-90 tw-text-xs tw-text-disabled">
        {{ $formatTime(message.time || message.created_at) }}
      </time>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="message.status as number"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />

  <q-dialog
    persistent
    v-model="openModal"
    @before-show="mountDialog"
    @before-hide="unMountDialog"
  >
    <q-card style="width: 500px; max-width: 80vw">
      <q-card-section
        class="tw-bg-[var(--cl-bg-active)] text-h6 row items-center"
      >
        Salvar Contato
      </q-card-section>

      <q-card-section class="tw-bg-white tw-text-wc-gray">
        <div class="q-gutter-md">
          <q-input
            v-model="form.name"
            label="Nome *"
            maxlength="75"
            required
            filled
          >
            <template #prepend>
              <q-icon name="o_person" />
            </template>
          </q-input>

          <q-input
            v-model="form.number"
            type="tel"
            label="Celular *"
            maxlength="75"
            required
            filled
          >
            <template #prepend>
              <q-icon name="o_dialpad" />
            </template>

            <template #append>
              <q-icon name="o_help">
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                >
                  <strong class="tw-text-base">
                    formato: DDI + DDD + Celular
                  </strong>
                </q-tooltip>
              </q-icon>
            </template>
          </q-input>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn
          v-close-popup
          flat
          dense
          color="red"
          label="Fechar"
          :disable="saving"
        />
        <q-separator :vertical="true" />
        <q-btn
          flat
          dense
          color="primary"
          label="Salvar"
          :disable="saving"
          :loading="saving"
          @click="postSaveContact"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
