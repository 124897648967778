<script setup lang="ts">
import { createVideoThumbnail } from '@/Helpers/Files'
import { v4 as uuidv4 } from 'uuid'

import _ from 'lodash'

const Reactions = defineAsyncComponent(
  () => import('../Partials/Reactions.vue')
)

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const thumb = ref('')
const openModal = ref(false)
const file = ref()

const download = () => {
  downloadBuilder(String(props.message.message.media?.link), `${uuidv4()}.mp4`)
}

onBeforeMount(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        })
      )
    ).data
  }

  await nextTick()
  if (props.message.message.media?.thumb !== undefined) {
    thumb.value = props.message.message.media?.thumb
  } else {
    thumb.value = await createVideoThumbnail(file.value)
  }
})

const fromContact = computed(() => props.message.fromContact)
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-[3px] tw-rounded-lg tw-shadow-md tw-group"
    style="min-width: 400px; max-width: 400px"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="fromContact"
      :sender-name="message.sender_name"
      :from-contact="fromContact"
      :is-media="true"
    />
    <MessageActionsV2
      :message="message"
      :enable-download="true"
      :is-media="true"
      size="sm"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <figure class="tw-relative">
      <div
        v-if="thumb === ''"
        class="tw-flex tw-min-h-[200px] tw-min-w-[200px] tw-items-center tw-justify-center"
      >
        <q-circular-progress
          indeterminate
          rounded
          size="xl"
          color="black"
          class="q-ma-lg"
        />
      </div>
      <div
        v-else
        class="tw-relative tw-rounded-md tw-overflow-hidden"
        @click="openModal = true"
      >
        <div
          class="tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-w-full tw-h-full tw-bg-black/50 tw-backdrop-blur-sm tw-cursor-pointer"
        >
          <span
            class="tw-absolute tw-bg-white/40 tw-rounded-full tw-w-[72px] tw-h-[72px] tw-left-[50%] tw-top-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-flex tw-justify-center tw-items-center"
          >
            <q-icon name="o_play_arrow" size="64px" color="black" />
          </span>
        </div>
        <q-img :src="thumb" :ratio="16 / 9" />
      </div>
      <figcaption v-if="message.message.caption" class="file-caption">
        <div class="tw-pt-1">
          <SenderName
            v-if="!fromContact"
            :sender-name="message.sender_name"
            :is-media="true"
            :from-contact="fromContact"
          />
          <span v-html="formatMarkdown(message.message.caption)" />
        </div>
        <div
          class="tw-right-2 tw-bottom-[0px] tw-inline-flex tw-float-right tw-z-[51]"
        >
          <time class="tw-opacity-90 tw-text-xs tw-text-[#667781]">
            {{ $formatTime(message.time || message.created_at) }}
          </time>
          <MessageStatus
            v-if="!fromContact"
            class="tw-pl-1"
            :status="message.status as number"
          />
        </div>
      </figcaption>
      <div v-else class="tw-absolute tw-right-1 tw-bottom-[0px] tw-z-[51]">
        <time class="tw-opacity-90 tw-text-xs tw-text-white tw-font-medium">
          {{ $formatTime(message.time || message.created_at) }}
        </time>
        <MessageStatus
          v-if="!fromContact"
          class="tw-pl-1"
          :status="message.status as number"
        />
      </div>
    </figure>
  </div>

  <Reactions :from-contact="fromContact" :reactions="message.reactions" />

  <MediaOverlay
    v-if="openModal"
    :message="message"
    @close="() => (openModal = false)"
  />
</template>
