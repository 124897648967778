import linkifyHtml from 'linkify-html'

function isInsideLink(content, links) {
  if (!links) return false
  return links.some((link) => link.includes(content))
}

export const formatMarkdown = (
  text: string,
  ignoreLink = false,
  breakLine = true
): string => {
  text = String(text)
  text = text.trim()

  text = text
    .replace(/&/g, '&amp;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
    .replace(/"/g, '&quot;')
  text = linkifyHtml(text, {
    className: 'chatLink',
    attributes: { target: '_blank' },
  })

  const linkRegex = /<a\b[^>]*>.*?<\/a>/g
  const matches = text.match(linkRegex)

  if (matches) {
    matches.forEach((match) => {
      const placeholder = `__LINK${matches.indexOf(match)}__`
      text = text.replace(match, placeholder)
    })
  }

  // Substitui asteriscos por <b> e </b), ignorando links
  text = text.replace(/\*(.*?)\*/g, (match, content) => {
    if (!isInsideLink(content, matches)) {
      return `<b>${content}</b>`
    }
    return match
  })

  // Substitui underlines por <i> e </i), ignorando links
  text = text.replace(/_(.*?)_/g, (match, content) => {
    if (!isInsideLink(content, matches)) {
      return `<i>${content}</i>`
    }
    return match
  })

  // Substitui o ~ por <s> e </s), ignorando links
  text = text.replace(/~(.*?)~/g, (match, content) => {
    if (!isInsideLink(content, matches)) {
      return `<s>${content}</s>`
    }
    return match
  })

  // Restaura os links
  if (matches) {
    matches.forEach((match, index) => {
      const placeholder = `__LINK${index}__`
      text = text.replace(placeholder, match)
    })
  }

  if (breakLine) {
    text = text.replace(/\n/g, '<br />')
  }

  if (ignoreLink) {
    return text
  }

  return text
}

export const removeMarkDown = (text: string) => {
  text = text
    .replace(/&/g, '&amp;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
    .replace(/"/g, '&quot;')

  // substitui <b> e </b> por asteriscos
  text = text.replace(/<b>(.*?)<\/b>/g, '*$1*')
  // substitui <i> e </i> por underlines
  text = text.replace(/<i>(.*?)<\/i>/g, '_$1_')
  // substitui <s> e </s> por ~
  text = text.replace(/<s>(.*?)<\/s>/g, '~$1~')

  // substitui asteriscos por NADA
  text = text.replace(/\*(.*?)\*/g, '$1')
  // substitui underlines por NADA
  text = text.replace(/_(.*?)_/g, '$1')
  // substitui o ~ for NADA
  text = text.replace(/~(.*?)~/g, '$1')

  return text
}

export const formatCaption = (
  text: string,
  ignoreMarkDown = false,
  endSlice = 70
) => {
  if (!text) return

  if (!ignoreMarkDown) {
    text = formatMarkdown(text, true, false)
  } else {
    text = removeMarkDown(text)
  }

  return text.slice(0, endSlice)
}
